export default [
  {
    title: 'menu.dashboard',
    route: 'admin.dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'menu.sensors',
    route: 'admin.sensors',
    icon: 'CameraIcon'
  }
]
